<template>
  <div class="info">
    <div class="jobshwheader"></div>
    <div class="min_body">
      <div class="job_describe">
        <!-- 职位要求及待遇岗位 -->
        <div class="job_describe_top">
          <div class="jjxx">走访信息</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter">
          <div class="job_describe_cengter_header">{{ info.Shop_Name }}</div>

          <div class="user_undergo_box">
            <div class="user_undergo user_undergo_a">
              走访时间：{{ info.Date_OP }}
            </div>
            <div class="user_undergo user_undergo_a">
              走访照片：
            </div>
            <img
              v-for="(item, i) in info.FileList"
              :key="i"
              :src="item.Url"
              style="width: 80px;height:80px;display: inline-block;padding: 2px"
              @click="previewBefore"
            />
            <div class="user_undergo2 user_undergo_a">
              走访内容：{{ info.Content }}
            </div>
            <div class="user_undergo2 user_undergo_a">
              备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注：{{ info.Remark }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Base64 from '@/util/Base64'
  import { systemUrl } from '@/config'
  import { Toast } from 'vant'
  import { ImagePreview } from 'vant'
  import { getShopViewInfo } from '@/api/outDoor/shopView/shopView'
  import store from '@/store'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'ViewInfo',
    data() {
      return {
        shopView_ID: 0,
        systemUrl,
        info: {}, //详情数据
        fileList: [],
      }
    },
    created() {
      this.shopView_ID = Base64.decode(this.$route.query.shopView_ID)
      this.fetchData()
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'viewInfo',
        '城管-店铺走访详情',
        'zzdcg.yy.gov.cn/viewInfo'
      )
    },

    methods: {
      //获取劝导详情
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getShopViewInfo({ ID: this.shopView_ID })
        let fileList = data.FileList
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            fileList[i].Url = systemUrl + fileList[i].Url
            this.fileList.push(fileList[i].Url)
          }
        }
        data.FileList = fileList
        this.info = data
        Toast.clear()
      },

      previewBefore() {
        ImagePreview({
          images: this.fileList,
        })
      },

      reply() {
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  .job_describe_cengter {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }
  .job_describe_cengter2 {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    font-weight: 700;
    color: #181818;
  }

  .job_describe_top {
    position: relative;
    width: 100%;
    padding: 0.273333rem 0.373333rem;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    padding-left: 0.473333rem;
    font-size: 0.426667rem;
    font-weight: bold;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../../assets/images/workBench/detail.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user_undergo2 {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 2;
    color: #333;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
  .corporate_information {
    margin-top: 0.266667rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
</style>
